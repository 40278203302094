export interface Position {
	x: number;
	y: number;
}

interface EyeTrackingJoyStickProps {
	coordinates: Position[];
}

const joystickContainerWidth = 160;
const joystickContainerHeight = 110;

export const EyeTrackingJoyStick = ({
	coordinates,
}: EyeTrackingJoyStickProps) => {
	return (
		<div
			style={{
				position: "relative",
				width: joystickContainerWidth,
				height: joystickContainerHeight,
				borderRadius: "50%",
				overflow: "hidden",
				margin: "auto",
				cursor: "pointer",
				paddingRight: "10px",
				border: "1px solid #DEE3E9",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					width: 35,
					height: 35,
					borderRadius: "50%",
					backgroundColor: "#DAE1F2",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					transform: "translate(-50%, -50%)",
				}}
			></div>
			{coordinates.map((coordinate, index) => (
				<div
					key={index}
					style={{
						position: "absolute",
						backgroundColor: "black",
						width: 8,
						height: 8,
						borderRadius: "50%",
						left: coordinate.x + joystickContainerWidth / 2,
						top: coordinate.y + joystickContainerHeight / 2,
					}}
				/>
			))}
		</div>
	);
};
