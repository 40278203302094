import React from 'react';
import { Route } from 'react-router-dom';
import LoadingPage from './components/common/LoadingPage';

import PickListTypeListPage from 'admin/PickList/PickListTypeListPage';
import PickListTypeDetailsPage from 'admin/PickList/PickListTypeDetailsPage';
import PickListItemListPage from 'admin/PickList/PickListItemListPage';
import PickListItemDetailPage from 'admin/PickList/PickListItemPage';
import UserListPage from 'admin/Users/UserListPage';
import UserDetailPage from 'admin/Users/UserDetailPage';

import PromiseTestPage from 'test/PromiseTestPage';
import MarkPage from 'test/MarkPage';

import LandingPage from 'common/LandingPage';


// SFST imports
import CaseListingPage from 'case/CaseListingPage';
import CaseDetailPage from 'case/CaseDetailPage';
import CaseReportPage from 'report/CaseReportPage';
import SummaryReportPage from 'report/SummaryReportPage';
import LocationsReportPage from 'report/LocationsReportPage';
import SettingsPage from 'settings/SettingsPage';

const Routes: React.FC = () => {
  return (
    <React.Fragment>
      <Route exact path="/" component={LandingPage} />
	  <Route exact path="/dashboard" component={LandingPage} />
      
      <Route path="/dev/loading" component={LoadingPage} />
      <Route path="/test/promises" component={PromiseTestPage} />
      <Route path="/test/mark" component={MarkPage} />

	  <Route exact={true} path="/reports/case-reports" component={CaseReportPage} />
	  <Route exact={true} path="/reports/summary-reports" component={SummaryReportPage} />
	  <Route exact={true} path="/reports/location-reports" component={LocationsReportPage} />

	  <Route exact={true} path="/cases" component={CaseListingPage} />
	  <Route exact={true} path="/cases/:caseId" component={CaseDetailPage} />

      <Route exact path="/admin/pick-lists" component={PickListTypeListPage} />
      <Route exact path="/admin/pick-lists/:pickListTypeId" component={PickListTypeDetailsPage} />
      <Route exact path="/admin/pick-lists/:pickListTypeId/items" component={PickListItemListPage} />	  
      <Route exact path="/admin/pick-lists/:pickListTypeId/items/:itemId" component={PickListItemDetailPage} />
	  <Route exact path="/admin/users" component={UserListPage} />
	  <Route exact path="/admin/users/:userId" component={UserDetailPage} />
	  <Route exact path="/admin/settings" component={SettingsPage} />

    </React.Fragment>
  );
}
export default Routes;
