import React from "react";

import T from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import YesNoAnswerList from "../../YesNoAnswerList";
import DateUpdated from "../../DateUpdated";
import useDateFormatter from "common/useDateFormatter";
import { useTranslation } from "react-i18next";
import { formatTimeFromSeconds, getItemLabel, getItemSeverity, getMinutesAndSeconds } from "./mrbUtils";

interface IProps {
	caseInfo: CaseDTO;
	mrbTestResults: ModifiedRombergTestDTO[];
	mrbTestItemResults: ModifiedRombergTestItemDTO[];
	mrbInstructions: YesNoRecordDTO[];
    swayDirections: PickListItemResponseDTO[];
	onNotesChanged: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	>;
	submittedCase: boolean;
	subjectName: string;
	order: number;
}

const CaseDetailMRB = (props: IProps) => {
	const {
		mrbTestResults,
        mrbTestItemResults,
		mrbInstructions,
        swayDirections,
		caseInfo,
		subjectName,
		submittedCase,
		order,
	} = props;

	const { formatTime, formatDateTime } = useDateFormatter(
		caseInfo.ianaTimeZone
	);

	const { t } = useTranslation("modifiedRomberg");

	return (
		<>
			<T variant="h2">{`${order}. Modified Romberg`}</T>
			{mrbTestResults.map((data, index) => (
				<div className="pt-3" key={index}>
					<T variant="h3">
						{data.resetDate
							? `Incomplete Attempt ${index + 1}`
							: "Completed Attempt"}
					</T>
					<T variant="h4">Setup</T>
					<T variant="h6" component="h5">
						Response to Instructions:{" "}
					</T>
					<T fontStyle="italic" sx={{ mt: 2 }}>
						{t(
							"ModifiedRomberg.setupInstructions",
							"Place your feet together. Keep your arms to your side. Tilt your head back and close your eyes. When told to begin, estimate 30 seconds, tell me out loud when you think 30 seconds has transpired. Are the instructions understood?"
						)}
					</T>
					<YesNoAnswerList
						answers={mrbInstructions}
						testId={data.id}
						subjectName={subjectName}
						timeFormatter={formatTime}
					/>
					<T variant="h4" sx={{ mt: 5 }}>
						Test
					</T>
					<T variant="h6" component="h5">
						Results:{" "}
					</T>
					<TableContainer sx={{ mb: 5 }}>
						<Table>
							<TableHead
								sx={{
									"& .MuiTableCell-head": {
										fontWeight: 700,
										borderBottom: 2,
										textTransform: "uppercase",
										paddingBottom: 1,
									},
								}}
							>
								<TableCell sx={{position: "relative", right: "14px"}}>Total Time</TableCell>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell sx={{ position: "relative", right: "14px" }}>
										{getMinutesAndSeconds(data.timeElapsed!)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<T sx={{ mb: 4 }}>
						<div>
							<b>How long was that? </b>
						</div>
						{data.testDuration}
					</T>
					<T sx={{ mb: 4 }}>
						<div>
							<b>How did you get to that number, time, answer, etc? </b>
						</div>
						{data.testDurationDescription}
					</T>
						<TableContainer sx={{ mt: 2, pb:4 }}>
							<Table>
								<TableHead
									sx={{
										"& .MuiTableCell-head": {
											fontWeight: 700,
											borderBottom: 2,
											textTransform: "uppercase",
											paddingBottom: 1,
										},
									}}
								>
									<TableRow>
										<TableCell sx={{ position: 'relative', right:'14px' }}>
											Actions
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>Distance</TableCell>
										<TableCell sx={{ textAlign: "right" }}>
											Time
										</TableCell>
									</TableRow>
								</TableHead>
								{mrbTestItemResults.map((data: any, index) => (
									<TableBody>
										<TableRow>
											<TableCell sx={{ position: 'relative', right:'14px' }}>{getItemLabel(data, swayDirections)}</TableCell>
											<TableCell align="center">
                                                {getItemSeverity(data)}
											</TableCell>
											<TableCell align="right">
                                                {formatTimeFromSeconds(data.timeStamp)}
											</TableCell>
										</TableRow>
									</TableBody>
								))}
							</Table>
						</TableContainer>

					{data.resetDate && (
						<>
							<T variant="h4">Restarted: </T>
							<T>
								The test was restarted at{" "}
								<strong>{formatDateTime(data.resetDate)}</strong>
							</T>
							<T>
								<strong>Restart Notes: </strong>
								{data.resetNote || (
									<T fontStyle="italic">No restart notes added.</T>
								)}
							</T>
						</>
					)}
				</div>
			))}
			<T variant="h3">Other Indicia</T>
			{submittedCase ? (
				<TextField
					multiline
					rows={9}
					fullWidth
					name="hgnTestAdditionalNotes"
					label="Post-Test Comments:"
					value={caseInfo.mrbTestAdditionalNotes}
					onChange={(evt: any) => props.onNotesChanged(evt)}
				/>
			) : (
				<p>
					<strong>Post-Test Comments: </strong>
					{caseInfo.mrbTestAdditionalNotes ? (
						<React.Fragment>
							{caseInfo.mrbTestAdditionalNotes}
							<DateUpdated
								dateUpdated={caseInfo.dateUpdated!}
								formatter={formatTime}
							/>
						</React.Fragment>
					) : (
						<T fontStyle="italic">No post-test comments added.</T>
					)}
				</p>
			)}
		</>
	);
};
export default CaseDetailMRB;